export enum NotificationTypeEnum {
  GENERAL = "GENERAL",
  WORKSHEET = "WORKSHEET",
  TIME_OFF = "TIME_OFF",
  VEHICLE_ISSUE_NEW = "VEHICLE_ISSUE_NEW",
  QUALIFICATION_EXPIRING = "QUALIFICATION_EXPIRING",
  ITEM_REVIEW = "ITEM_REVIEW",
}

export const getNotificationTypeLabel = (type: NotificationTypeEnum): string => {
  switch (type) {
    case NotificationTypeEnum.GENERAL:
      return "General";
    case NotificationTypeEnum.WORKSHEET:
      return "Parte";
    case NotificationTypeEnum.TIME_OFF:
      return "Ausencia";
    case NotificationTypeEnum.VEHICLE_ISSUE_NEW:
      return "Nueva incidencia de vehículo";
    case NotificationTypeEnum.ITEM_REVIEW:
      return "Revisión de artículo";
    case NotificationTypeEnum.QUALIFICATION_EXPIRING:
        return "Caducidad de formación";
    default:
      return "-";
  }
};

export const getNotificationTypeOptions = (includeOnly?: string[]): { label: string; value: string }[] => {
  const keys = Object.keys(NotificationTypeEnum).filter(
    (key: string) =>
      typeof NotificationTypeEnum[key as keyof typeof NotificationTypeEnum] === "string" &&
      (!includeOnly || includeOnly.includes(key))
  );
  return keys.map((val) => {
    const value = NotificationTypeEnum[val as keyof typeof NotificationTypeEnum];
    return { label: getNotificationTypeLabel(value), value: value };
  });
};
