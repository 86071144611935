import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ClientGroupDto } from "@/core/features/client-groups/client-group.dto";
import { Type } from "class-transformer";

export class ClientDto extends BaseEntityDto {
  name: string;

  vatNumber?: string;

  sapCode?: string;

  address?: string;

  country?: string;

  @Type(() => ClientGroupDto)
  clientGroup: ClientGroupDto;
  clientGroupId: string;

  toString() {
    return this.name;
  }
}
