import { Transform, Type } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { ProjectHasUserAnalysisDto } from "@/core/features/project-economic-analyses/project-has-user-analysis.dto";
import { ProjectHasVehicleAnalysisDto } from "@/core/features/project-economic-analyses/project-has-vehicle-analysis.dto";
import { ProjectHasOnCallAnalysisDto } from "@/core/features/project-economic-analyses/project-has-on-call-analysis.dto";
import { ProjectHasDietAnalysisDto } from "@/core/features/project-economic-analyses/project-has-diet-analysis.dto";

export class AllocationCostDto {
  @Type(() => ProjectHasDietAnalysisDto)
  dietCosts: ProjectHasDietAnalysisDto[];

  @Transform(({ value }) => toEuros(value))
  dietCostTotal: number;

  @Type(() => ProjectHasUserAnalysisDto)
  userCosts: ProjectHasUserAnalysisDto[];

  @Transform(({ value }) => toEuros(value))
  userCostTotal: number;

  @Type(() => ProjectHasVehicleAnalysisDto)
  vehicleCosts: ProjectHasVehicleAnalysisDto[];

  @Transform(({ value }) => toEuros(value))
  vehicleCostTotal: number;

  @Type(() => ProjectHasOnCallAnalysisDto)
  onCallCosts: ProjectHasOnCallAnalysisDto[];

  @Transform(({ value }) => toEuros(value))
  onCallCostTotal: number;

  @Transform(({ value }) => toEuros(value))
  totalCost: number;
}
