import { RecordDto } from "@/core/common/entities/record.dto";
import { get } from "lodash";
import { toEuros } from "@/utils/numberUtils";

export class ItemRecordDto extends RecordDto {
  itemId: string;
  translateEntityName = "el artículo";

  translateKeys = {
    notes: "las notas",
    itemModelId: "el modelo del artículo",
    serialNumber: "el número de serie",
    companyId: "la empresa",
    purchaseDate: "la fecha de compra",
    warehouseId: "el almacén",
    amortizationStartDate: "la fecha de inicio de amortización",
    price: "el precio",
    locationId: "la ubicación",
    vehicleId: "el vehículo",
    nextReviewDate: "la fecha de próxima revisión",
    appearsInChecklist: "aparece en la checklist",
    generatesIssuesInChecklist: "genera incidencia en la checklist",
    certificateNumber: "el número de certificado",
    archivedAt: "archivado",
  };

  // @Override
  getTargetField(): string {
    if (!this.targetField) return;
    return get(this.translateKeys, this.targetField, this.targetField);
  }

  // @Override
  transformFn(value: unknown, originalValue: unknown): string {
    if (this.targetField === "price") {
      return value && !isNaN(Number(value)) ? `${toEuros(Number(value))}€` : "0€";
    }
    return super.transformFn(value, originalValue);
  }
}
