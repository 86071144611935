import { Expose, Type } from "class-transformer";
import { CreateFileDto } from "../file/create-file.dto";

export class CreateGeneralDocumentDto {
  @Expose()
  name: string;

  @Type(() => CreateFileDto)
  @Expose()
  file: CreateFileDto;
}
