import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OperativeUnitDto } from "./operative-unit.dto";
import { CreateOperativeUnitDto } from "./create-operative-unit.dto";
import { UpdateOperativeUnitDto } from "./update-operative-unit.dto";
import { IRequest } from "@/interfaces/IRequest";
import { OptionType } from "@/types/OptionType";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { merge } from "lodash";
import { OperativeUnitWithAllocationCostDto } from "@/core/features/operative-units/operative-unit-with-allocation-cost.dto";
import { OperativeUnitWithTotalAllocationCostDto } from "@/core/features/operative-units/operative-unit-with-total-allocation-cost.dto";

export class OperativeUnitsResource extends ResourceBaseService<
  OperativeUnitDto,
  CreateOperativeUnitDto,
  UpdateOperativeUnitDto
> {
  protected EntityDto = OperativeUnitDto;
  protected CreateEntityDto = CreateOperativeUnitDto;
  protected UpdateEntityDto = UpdateOperativeUnitDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/operative-units");
  }

  async optionLoader(inputValue: string, customRequest?: IRequest): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name", "code"] } : {};
    request.sortBy = "code";
    if (customRequest) merge(request, customRequest);
    const { data } = await this.findAll(request);

    return data.map((value) => value.mapEntityToOptionType([value.toString()]));
  }

  async optionLoaderMine(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name", "code"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAllMine(request);
    return data.map((value: OperativeUnitDto) => value.mapEntityToOptionType([value.toString()]));
  }

  async findAllMine(request: IRequest): Promise<FindManyResult<OperativeUnitDto>> {
    return this.apiHttpClient
      .request<OperativeUnitDto, FindManyResult<OperativeUnitDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/mine",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(OperativeUnitDto, findManyResult.data);
        return findManyResult;
      });
  }

  async findProvidingResourcesOperativeUnit(
    request: IRequest
  ): Promise<FindManyResult<OperativeUnitWithAllocationCostDto>> {
    return this.apiHttpClient
      .request<null, FindManyResult<OperativeUnitWithAllocationCostDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/providing-resources",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => {
        result.data = plainToInstance(OperativeUnitWithAllocationCostDto, result.data);
        return result;
      });
  }

  async findProvidingResourcesOperativeUnitTotal(request: IRequest): Promise<OperativeUnitWithTotalAllocationCostDto> {
    return this.apiHttpClient
      .request<null, OperativeUnitWithTotalAllocationCostDto>(
        HttpRequest.create({
          url: this.resourceUrl + "/providing-resources/total",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(OperativeUnitWithTotalAllocationCostDto, result));
  }
}
