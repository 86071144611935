export enum VehicleMaintenanceStatusEnum {
  EXPIRED = "EXPIRED",
  NEAR_EXPIRATION = "NEAR_EXPIRATION",
  VALID = "VALID",
  COMPLETED = "COMPLETED",
}

export const getVehicleMaintenanceStatusLabel = (status: VehicleMaintenanceStatusEnum): string => {
  switch (status) {
    case VehicleMaintenanceStatusEnum.EXPIRED:
      return "Caducado";
    case VehicleMaintenanceStatusEnum.NEAR_EXPIRATION:
      return "Cerca de caducar";
    case VehicleMaintenanceStatusEnum.VALID:
      return "Vigente";
    case VehicleMaintenanceStatusEnum.COMPLETED:
      return "Completado";
    default:
      return status;
  }
};
