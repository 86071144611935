import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { VehicleDto } from "@/core/features/vehicles/vehicle.dto";
import { toKilometers } from "@/utils/numberUtils";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { VehicleMaintenanceStatusEnum } from "@altertec_gparn/lib";

export class VehicleMaintenanceDto extends BaseEntityDto {
  @Type(() => VehicleDto)
  vehicle: VehicleDto;
  vehicleId: string;

  @Transform(({ value }) => parseApiDate(value))
  dueDate?: Date;

  @Transform(({ value }) => toKilometers(value))
  mileageLimit?: number;

  @Transform(({ value }) => parseApiDate(value))
  completionDate?: Date;

  title: string;

  status: VehicleMaintenanceStatusEnum;

  get isExpired(): boolean {
    return this.status === VehicleMaintenanceStatusEnum.EXPIRED;
  }

  get isNearExpiration(): boolean {
    return this.status === VehicleMaintenanceStatusEnum.NEAR_EXPIRATION;
  }

  get isValid(): boolean {
    return this.status === VehicleMaintenanceStatusEnum.VALID;
  }

  get isCompleted(): boolean {
    return this.status === VehicleMaintenanceStatusEnum.COMPLETED;
  }
}
