import { plainToInstance, Transform } from "class-transformer";
import { toEuros, toHours } from "@/utils/numberUtils";
import { UserDto } from "@/core/features/users/user.dto";

export class ProjectHasUserAnalysisDto {
  projectId: string;

  @Transform(({ value }) => toEuros(value))
  hourlyRate: number;

  @Transform(({ value }) => toEuros(value))
  hourlyRateQualificationFee: number;

  operativeUnitId: string;

  @Transform(({ value, obj }) =>
    plainToInstance(UserDto, {
      username: value,
      operativeUnitId: obj.operativeUnitId,
      id: obj.userId,
    })
  )
  user: UserDto;

  @Transform(({ value }) => toHours(value))
  hours: number;

  @Transform(({ value }) => toEuros(value))
  totalCost = 0;
}
