import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { GeneralDocumentDto } from "./general-document.dto";
import { CreateGeneralDocumentDto } from "./create-general-document.dto";
import { UpdateGeneralDocumentDto } from "./update-general-document.dto";
import { IRequest } from "@/interfaces/IRequest";
import { merge } from "lodash";
import { OptionType } from "@/types/OptionType";

export class GeneralDocumentsResource extends ResourceBaseService<
  GeneralDocumentDto,
  CreateGeneralDocumentDto,
  UpdateGeneralDocumentDto
> {
  protected EntityDto = GeneralDocumentDto;
  protected CreateEntityDto = CreateGeneralDocumentDto;
  protected UpdateEntityDto = UpdateGeneralDocumentDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/general-documents");
  }

  async optionLoader(inputValue: string, customRequest: IRequest = {}): Promise<OptionType[]> {
    const request: IRequest = inputValue
      ? {
          search: inputValue,
          searchBy: ["id", "name", "file.filename"],
        }
      : {};
    request.sortBy = "id";
    const { data } = await this.findAll(merge(request, customRequest));
    return data.map((value) => value.mapEntityToOptionType([value.toString()]));
  }
}
