import { Type } from "class-transformer";
import { FileDto } from "../file/file.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class GeneralDocumentDto extends BaseEntityDto {
  name: string;

  @Type(() => FileDto)
  file: FileDto;

  toString() {
    return this.file?.filename;
  }
}
