import { Expose } from "class-transformer";

export class MoveItemDto {
  @Expose()
  vehicleId?: string;

  @Expose()
  warehouseId?: string;

  @Expose()
  locationId?: string;
}
