import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { ItemModelDto } from "@/core/features/item-models/item-model.dto";
import { CompanyDto } from "@/core/features/companies/company.dto";
import { toEuros } from "@/utils/numberUtils";
import { LocationDto } from "@/core/features/locations/location.dto";
import { VehicleDto } from "@/core/features/vehicles/vehicle.dto";
import { parseApiDate, toUTCDate } from "@/utils/dates/dateFormats";
import { WarehouseDto } from "@/core/features/warehouses/warehouse.dto";
import { addDays, addMonths, differenceInMonths } from "date-fns";
import { isDateBefore } from "@/utils/dates/dateUtils";
import { FileDto } from "@/core/features/file/file.dto";

export class ItemDto extends BaseEntityDto {
  code: string;

  @Type(() => ItemModelDto)
  itemModel: ItemModelDto;
  itemModelId: string;

  serialNumber?: string;

  @Type(() => CompanyDto)
  company: CompanyDto;
  companyId: string;

  @Transform(({ value }) => parseApiDate(value))
  purchaseDate: Date;

  @Transform(({ value }) => parseApiDate(value))
  amortizationStartDate: Date;

  @Transform(({ value }) => toEuros(value))
  price: number;

  @Type(() => WarehouseDto)
  warehouse: WarehouseDto;
  warehouseId: string;

  @Type(() => LocationDto)
  location: LocationDto;
  locationId: string;

  @Type(() => VehicleDto)
  vehicle: VehicleDto;
  vehicleId: string;

  @Transform(({ value }) => parseApiDate(value))
  nextReviewDate?: Date;

  certificateNumber?: string;

  @Transform(({ value }) => parseApiDate(value))
  archivedAt?: Date;

  notes?: string;

  @Type(() => FileDto)
  files: FileDto[];

  appearsInChecklist: boolean;

  generatesIssuesInChecklist: boolean;

  qrCode?: string;

  toString(): string {
    return this.code;
  }

  accumulatedDepreciation(differenceInDays: number) {
    return differenceInDays * this.dailyAmortization;
  }

  bookValue(differenceInDays: number) {
    const bookValue = this.price - this.accumulatedDepreciation(differenceInDays);
    return bookValue > 0 ? bookValue : 0;
  }

  amortizationProgress(daysTotal: number) {
    return (daysTotal / this.itemModel.lifespanInDays) * 100;
  }

  get archived(): boolean {
    return !!this.archivedAt;
  }

  get isExpired(): boolean {
    return this.nextReviewDate && isDateBefore(this.nextReviewDate, toUTCDate(new Date()), true);
  }

  get isNearExpiration(): boolean {
    return this.nextReviewDate && isDateBefore(this.nextReviewDate, addMonths(toUTCDate(new Date()), 1), true);
  }

  get dailyAmortization(): number {
    return this.getAmortization();
  }

  get monthlyAmortization(): number {
    return this.getAmortization(30);
  }

  get yearlyAmortization(): number {
    return this.getAmortization(30) * 12;
  }

  private getAmortization(days = 1): number {
    return (this.price / this.itemModel.lifespanInDays) * days;
  }

  get annualDepreciationRate(): number {
    const annualDepreciationRate = (1 / (this.itemModel.lifespan / 12)) * 100;
    return annualDepreciationRate < 100 ? annualDepreciationRate : 100;
  }

  get endLifespan(): Date {
    return addDays(this.amortizationStartDate, this.itemModel.lifespanInDays - 1);
  }

  getAccumulatedAmortization(date: Date) {
    const accumulatedAmortization = differenceInMonths(date, this.amortizationStartDate) * this.monthlyAmortization;
    return accumulatedAmortization > this.price ? this.price : accumulatedAmortization;
  }

  getNetBookValue(date: Date) {
    const netBookValue = this.price - this.getAccumulatedAmortization(date);
    return netBookValue < 0 ? 0 : netBookValue;
  }
}
