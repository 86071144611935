import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { CertificationDto } from "@/core/features/certifications/certification.dto";
import { CreateCertificationDto } from "@/core/features/certifications/create-certification.dto";
import { UpdateCertificationDto } from "@/core/features/certifications/update-certification.dto";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { IRequest } from "@/interfaces/IRequest";
import { OptionType } from "@/types/OptionType";
import { CertificationStatusEnum } from "@altertec_gparn/lib";
import { HttpRequest } from "@/core/common/http/http-request";
import { CreateFileDto } from "@/core/features/file/create-file.dto";
import { TimeOffDto } from "@/core/features/times-off/time-off.dto";
import { DeleteFileDto } from "@/core/features/file/delete-file.dto";
import { plainToInstance } from "class-transformer";
import { CreateCertificationWizardDto } from "@/core/features/certifications/create-certification-wizard.dto";
import { AddExpensesToCertificationDto } from "@/core/features/certifications/add-expenses-to-certification.dto";
import { UpdateCertificationStatusDto } from "@/core/features/certifications/update-certification-status.dto";
import { AddWorksheetsToCertificationDto } from "@/core/features/worksheets/add-worksheets-to-certification.dto";
import { cloneDeep } from "lodash";
import { CertificationEconomicAnalysisTotalDto } from "@/core/features/certification-economic-analyses/certification-economic-analysis-total.dto";
import { RemoveWorksheetsFromCertificationDto } from "@/core/features/worksheets/remove-worksheets-from-certification.dto";
import { AddOnCallsToCertificationDto } from "@/core/features/worksheets/add-on-calls-to-certification.dto";
import { RemoveOnCallsFromCertificationDto } from "@/core/features/worksheets/remove-on-calls-from-certification.dto";
import { SetClientToCertificationDto } from "@/core/features/certifications/set-client-to-certification.dto";

export class CertificationsResource extends ResourceBaseService<
  CertificationDto,
  CreateCertificationDto,
  UpdateCertificationDto
> {
  protected EntityDto = CertificationDto;
  protected CreateEntityDto = CreateCertificationDto;
  protected UpdateEntityDto = UpdateCertificationDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/certifications");
  }

  createManyAttachments(id: string, files: CreateFileDto[]): Promise<TimeOffDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }

  deleteAttachment(id: string, deleteFile: DeleteFileDto): Promise<TimeOffDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "DELETE",
        auth: true,
        body: deleteFile,
      })
    );
  }

  createWizard(createCertificationWizard: CreateCertificationWizardDto): Promise<CertificationDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/create-wizard",
        method: "POST",
        auth: true,
        body: createCertificationWizard,
      })
    );
  }

  async getCertificationsByProjectId(projectId: string, request: IRequest): Promise<FindManyResult<CertificationDto>> {
    const req = cloneDeep(request);
    if (!req.join) req.join = [];
    if (!req.join.includes("certificationHasProjects")) req.join.push("certificationHasProjects");

    if (!req.filters) req.filters = {};
    req.filters["certificationHasProjects.project"] = projectId;

    return this.findAll(req);
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "code"] } : {};
    request.sortBy = "code";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.code]));
  }

  changeStatus(id: string, status: CertificationStatusEnum): Promise<CertificationDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/change-status",
        method: "PATCH",
        auth: true,
        body: plainToInstance(UpdateCertificationStatusDto, { certificationId: id, status: status }),
      })
    );
  }

  addExpenses(id: string, expenseIds: string[]): Promise<CertificationDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/add-expenses",
        method: "PATCH",
        auth: true,
        body: plainToInstance(AddExpensesToCertificationDto, { certificationId: id, expenseIds: expenseIds }),
      })
    );
  }

  async addWorksheetsToCertification(certificationId: string, worksheetIds: string[]): Promise<void> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/add-worksheets",
        method: "PATCH",
        auth: true,
        body: plainToInstance(AddWorksheetsToCertificationDto, {
          certificationId: certificationId,
          worksheetIds: worksheetIds,
        }),
      })
    );
  }

  async removeWorksheetsFromCertification(certificationId: string, worksheetIds: string[]): Promise<void> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/remove-worksheets",
        method: "PATCH",
        auth: true,
        body: plainToInstance(RemoveWorksheetsFromCertificationDto, {
          certificationId: certificationId,
          worksheetIds: worksheetIds,
        }),
      })
    );
  }

  async addOnCallsToCertification(certificationId: string, onCallIds: string[]): Promise<void> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/add-on-calls",
        method: "PATCH",
        auth: true,
        body: plainToInstance(AddOnCallsToCertificationDto, {
          certificationId: certificationId,
          onCallIds: onCallIds,
        }),
      })
    );
  }

  async removeOnCallsFromCertification(certificationId: string, onCallIds: string[]): Promise<void> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/remove-on-calls",
        method: "PATCH",
        auth: true,
        body: plainToInstance(RemoveOnCallsFromCertificationDto, {
          certificationId: certificationId,
          onCallIds: onCallIds,
        }),
      })
    );
  }

  async deleteManyProjectsHaveCertifications(certificationId: string, projectHasCertificationIds: string[]) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + certificationId + "/delete-many",
        method: "PATCH",
        auth: true,
        body: { certificationId, projectHasCertificationIds },
      })
    );
  }

  async setClient(certificationId: string, body: SetClientToCertificationDto) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + certificationId + "/client",
        method: "PATCH",
        auth: true,
        body: body,
      })
    );
  }

  async getTotal(request: IRequest): Promise<CertificationEconomicAnalysisTotalDto> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/total",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(CertificationEconomicAnalysisTotalDto, result));
  }
}
