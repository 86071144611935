import { ProjectDto } from "@/core/features/projects/project.dto";
import { DateFormatterService } from "@/core/common/date-formatter/date-formatter.service";
import { IColumn } from "@/interfaces/IColumn";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { dateFormat, formatAppDate } from "@/utils/dates/dateFormats";
import { isSameDay } from "date-fns";

export function getProjectColumns(
  component: ComponentPublicInstance,
  orpView: boolean,
  requiredColumns: string[]
): IColumn<ProjectDto>[] {
  return [
    {
      label: component.$t("domain.dossier.name"),
      name: "dossier.description",
      field: (project: ProjectDto) => project.dossier?.description,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("dossier"),
      surtitleField: "dossier.code",
      classes: "auto-width",
      style: `--column-number:${requiredColumns.length}`,
      showArchivedTag: true,
      archivedField: (project: ProjectDto) => project.dossier.archived,
    },
    {
      label: component.$t("domain.clientGroup.name"),
      name: "dossier.clientGroup.name",
      field: (project: ProjectDto) => project.dossier?.clientGroup?.name,
      align: "left",
      sortable: true,
      required: requiredColumns.includes("clientGroup"),
      classes: "auto-width",
      style: `--column-number:${requiredColumns.length}`,
    },
    {
      label: component.$t("domain.project.code"),
      name: "code",
      field: orpView ? (project: ProjectDto) => project.code : "code",
      sortable: true,
      align: "left",
      required: requiredColumns.includes("code"),
      classes: "auto-width",
      prefixIcon: (project: ProjectDto) => (project.isUrgent && orpView ? "exclamation-circle" : null),
      prefixIconColor: () => "warning",
      style: `--column-number:${requiredColumns.length}`,
      showArchivedTag: true,
      archivedField: (project: ProjectDto) => project.isArchived(),
    },
    {
      label: component.$t("domain.project.description"),
      name: "description",
      field: "description",
      sortable: true,
      align: "left",
      required: requiredColumns.includes("description"),
      classes: "auto-width",
      style: `--column-number:${requiredColumns.length}`,
    },
    {
      label: component.$t("domain.project.scheduled"),
      name: "startDate",
      field: (project: ProjectDto) => {
        if (isSameDay(project.startDate, project.endDate))
          return `Planificado el ${formatAppDate(project.startDate, false, dateFormat)}`;
        return project.startDate && project.endDate
          ? `Planificado de ${new DateFormatterService().formatDateRange(project.startDate, project.endDate)}`
          : null;
      },
      sortable: true,
      align: "left",
      required: requiredColumns.includes("scheduled"),
      classes: "auto-width",
      style: `--column-number:${requiredColumns.length}`,
    },

    {
      label: "Fecha en la que se planificó",
      name: "plannedDate",
      sortable: true,
      field: "plannedDate",
      align: "left",
      required: requiredColumns.includes("plannedDate"),
      type: "date",
    },
    {
      label: component.$t("domain.intervention.name"),
      name: "intervention.code",
      field: (project: ProjectDto) => project.intervention?.code + " - " + project.intervention?.name,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("intervention"),
      classes: "auto-width",
      style: `--column-number:${requiredColumns.length}`,
    },
    {
      label: component.$t("domain.workplace.name"),
      name: "workplace.name",
      field: "workplace",
      sortable: true,
      align: "left",
      required: requiredColumns.includes("workplace"),
      classes: "auto-width",
      style: `--column-number:${requiredColumns.length}`,
    },
    {
      label: component.$t("domain.scope.name"),
      name: "scope",
      field: "scope",
      sortable: true,
      align: "left",
      required: requiredColumns.includes("scope"),
      classes: "auto-width",
      style: `--column-number:${requiredColumns.length}`,
    },
    {
      label: "Certificación",
      name: "projectHasCertifications.certification.code",
      field: (project: ProjectDto) =>
        project.projectHasCertifications?.length
          ? project.projectHasCertifications.map((projectHasCertification) => projectHasCertification.certification)
          : null,
      align: "left",
      required: requiredColumns.includes("projectHasCertifications"),
      type: "certification-statuses",
    },
    {
      name: "createdAt",
      label: "Creación",
      sortable: true,
      field: (project: ProjectDto) => formatAppDate(project.createdAt),
      required: requiredColumns.includes("createdAt"),
    },
    {
      label: component.$t("domain.project.firstWorksheet"),
      name: "firstWorksheet.date",
      sortable: false,
      field: (project: ProjectDto) => project.firstWorksheet?.date,
      align: "left",
      required: requiredColumns.includes("firstWorksheet"),
      type: "date",
    },
    {
      label: component.$t("domain.project.lastWorksheet"),
      name: "lastWorksheet.date",
      sortable: false,
      field: (project: ProjectDto) => project.lastWorksheet?.date,
      align: "left",
      required: requiredColumns.includes("lastWorksheet"),
      type: "date",
    },
    {
      label: component.$t("domain.operativeUnit.name"),
      name: "operativeUnit.code",
      field: (project: ProjectDto) => project.operativeUnit?.toString(),
      sortable: true,
      align: "left",
      required: requiredColumns.includes("operativeUnit"),
    },
    {
      label: component.$t("domain.project.status.name"),
      name: "status",
      field: "status",
      sortable: true,
      align: "left",
      required: requiredColumns.includes("status"),
      type: "status",
    },
    {
      label: component.$t("domain.element.name"),
      name: "element.name",
      field: (project: ProjectDto) => project.element?.name,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("element"),
    },
    {
      label: component.$t("domain.model.name"),
      name: "model.name",
      field: (project: ProjectDto) => project.model?.name,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("model"),
    },
    {
      label: component.$t("domain.project.orpTechnician"),
      name: "orpTechnician.username",
      field: (project: ProjectDto) => project.orpTechnician,
      align: "left",
      required: requiredColumns.includes("orpTechnician"),
      sortable: true,
      type: "user",
    },
    {
      label: component.$t("domain.project.documentationStatus.name"),
      name: "documentationStatus",
      field: "documentationStatus",
      sortable: true,
      align: "left",
      type: "doc-status",
      required: requiredColumns.includes("documentationStatus"),
    },
    {
      label: component.$t("domain.offer.name"),
      name: "offer.code",
      field: (project: ProjectDto) => project.offer?.code,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("offer"),
      classes: "auto-width",
      style: `--column-number:${requiredColumns.length}`,
    },
    {
      label: component.$t("domain.order.name"),
      name: "projectHasOrders.order.code",
      field: (project) => project.projectHasOrders?.map((projectHasOrder) => projectHasOrder.order.code).join(", "),
      sortable: true,
      align: "left",
      required: requiredColumns.includes("order"),
    },
    {
      label: component.$t("domain.project.budget"),
      name: "budget",
      field: "budget",
      sortable: true,
      align: "left",
      type: "currency",
      required: requiredColumns.includes("budget"),
    },
    {
      label: "Coste",
      name: "economicAnalysis.totalCost",
      field: (project) => project.economicAnalysis?.totalCost,
      sortable: true,
      align: "left",
      type: "currency",
      required: requiredColumns.includes("totalCost"),
    },
    {
      label: component.$t("domain.project.certifiedAmount"),
      name: "certifiedAmount",
      field: "certifiedAmount",
      sortable: true,
      align: "left",
      type: "currency",
      required: requiredColumns.includes("certifiedAmount"),
    },
    {
      label: "Comentarios",
      name: "topic.comments",
      field: (project: ProjectDto) => (project.topic.comments.length ? project.topic.comments.length : null),
      required: requiredColumns.includes("comments"),
      align: "left",
      type: "comments",
    },
    {
      label: "¿Tiene informe?",
      name: "hasReport",
      field: "hasReport",
      required: requiredColumns.includes("hasReport"),
      align: "center",
      type: "boolean",
    },
    {
      label: "Importe de rentabilidad",
      name: "economicAnalysis.profitability",
      field: (project) => project.economicAnalysis?.profitability || 0,
      required: requiredColumns.includes("profitability"),
      align: "center",
      type: "currency-with-color",
      sortable: true,
    },
    {
      name: "economicAnalysis.profitabilityPercentage",
      label: "Rentabilidad",
      field: (project) => project.economicAnalysis?.profitabilityPercentage,
      required: requiredColumns.includes("profitabilityPercentage"),
      noSearchable: true,
      sortable: true,
      type: "progress-bar",
      color: () => "positive",
    },
    {
      label: "Admite partes en el extranjero",
      name: "hasAbroadWork",
      field: "hasAbroadWork",
      required: requiredColumns.includes("hasAbroadWork"),
      align: "center",
      type: "boolean",
    },
  ];
}
