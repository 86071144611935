export enum NotificationPreferencesEnum {
    TIME_OFF_STATUS = "TIME_OFF_STATUS",
    WORKSHEET_STATUS = "WORKSHEET_STATUS",
    VEHICLE_ISSUE_NEW = "VEHICLE_ISSUE_NEW",
    QUALIFICATION_EXPIRING = "QUALIFICATION_EXPIRING",
    ITEM_REVIEW = "ITEM_REVIEW",
}

export const getNotificationPreferencesLabel = (type: NotificationPreferencesEnum): string => {
    switch (type) {
        case NotificationPreferencesEnum.TIME_OFF_STATUS:
            return "Cambios de estado";
        case NotificationPreferencesEnum.WORKSHEET_STATUS:
            return "Cambios de estado";
        case NotificationPreferencesEnum.VEHICLE_ISSUE_NEW:
            return "Nueva incidencia";
        case NotificationPreferencesEnum.QUALIFICATION_EXPIRING:
            return "Caducidad de formación";
        case NotificationPreferencesEnum.ITEM_REVIEW:
            return "Revisión de artículos";
        default:
        return "-";
    }
}
