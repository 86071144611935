import { RouteRecordRaw } from "vue-router";
import { PermissionEnum } from "@altertec_gparn/lib";

export default {
  path: "reports",
  name: "Reports",
  meta: { sectionAccess: "REPORTS", redirects: true },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "group-by",
      name: "Group by",
      meta: { permissions: [PermissionEnum.REPORTS_GROUP_BY] },
      redirect: { name: "Hours/tasks" },
      component: () => import("@/views/auth/reports/GroupBy.vue"),
      props: true,
      children: [
        {
          path: "client-groups",
          name: "Hours/client groups",
          component: () => import("@/modules/reports/components/tabs/HoursClientGroupTab.vue"),
        },
        {
          path: "interventions",
          name: "Hours/interventions",
          component: () => import("@/modules/reports/components/tabs/HoursInterventionTab.vue"),
        },
        {
          path: "models",
          name: "Hours/models",
          component: () => import("@/modules/reports/components/tabs/HoursModelTab.vue"),
        },
        {
          path: "tasks",
          name: "Hours/tasks",
          component: () => import("@/modules/reports/components/tabs/HoursTaskTab.vue"),
        },
        {
          path: "technicians",
          name: "Hours/technicians",
          component: () => import("@/modules/reports/components/tabs/HoursUserTab.vue"),
        },
        {
          path: "operative-units",
          name: "Hours/operative units",
          component: () => import("@/modules/reports/components/tabs/HoursOperativeUnitTab.vue"),
        },
        {
          path: "zones",
          name: "Hours/zones",
          component: () => import("@/modules/reports/components/tabs/HoursZoneTab.vue"),
        },
      ],
    },
    {
      path: "profitability",
      name: "Profitability",
      meta: { permissions: [PermissionEnum.REPORTS_PROFITABILITY] },
      redirect: { name: "Profitability Report" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      props: true,
      children: [
        {
          path: "report",
          name: "Profitability Report",
          component: () => import("@/views/auth/reports/profitability/ProfitabilityReport.vue"),
        },
      ],
    },
    {
      path: "allocation-cost",
      name: "Allocation Cost Report",
      meta: { permissions: [PermissionEnum.REPORTS_ALLOCATION_COST] },
      component: () => import("@/views/auth/reports/AllocationCostReport.vue"),
    },
    {
      path: "times-off",
      name: "Times Off Reports",
      redirect: { name: "Time Off Global Report" },
      meta: { permissions: [PermissionEnum.REPORTS_TIMES_OFF] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "global",
          name: "Time Off Global Report",
          component: () => import("@/views/auth/reports/TimeOffGlobalReport.vue"),
        },
        {
          path: "users",
          name: "Times Off Report by User",
          component: () => import("@/views/auth/reports/TimesOffByUserReport.vue"),
        },
        {
          path: "summary",
          name: "Times off Summary Report",
          component: () => import("@/views/auth/reports/TimesOffSummaryReport.vue"),
        },
      ],
    },
    {
      path: "user-activity",
      name: "User activity Reports",
      meta: { permissions: [PermissionEnum.REPORTS_USER_ACTIVITY] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      redirect: { name: "User activity Report" },
      props: true,
      children: [
        {
          path: ":userId?",
          name: "User activity Report",
          component: () => import("@/views/auth/reports/UserActivity.vue"),
        },
        {
          path: "projects",
          name: "Projects Report",
          component: () => import("@/views/auth/reports/ProjectsReport.vue"),
        },
        {
          path: "operative-units",
          name: "Operative Units Report",
          component: () => import("@/views/auth/reports/OperativeUnitsReport.vue"),
        },
        {
          path: "tasks",
          name: "Tasks Report",
          component: () => import("@/views/auth/reports/TasksReport.vue"),
        },
        {
          path: "diets",
          name: "Diets Report",
          component: () => import("@/views/auth/reports/DietReport.vue"),
        },
        {
          path: "time",
          name: "Time Report",
          component: () => import("@/views/auth/reports/TimeReport.vue"),
        },

        {
          path: "on-calls",
          name: "On Calls Report",
          component: () => import("@/views/auth/reports/OnCallReport.vue"),
        },
      ],
    },
    {
      path: "permissions",
      name: "Permissions Report",
      meta: { permissions: [PermissionEnum.REPORTS_PERMISSIONS] },
      component: () => import("@/views/auth/configuration/PermissionsReport.vue"),
    },
  ],
} as RouteRecordRaw;
